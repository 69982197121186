#menubar {
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0px;
  left: 0px;
}

.menuButton {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
  font-size: 25px;
  height: 30px;
  width: 12%;
  cursor: pointer;
}

.menuButton:hover {
  background-color: black;
  color: cornflowerblue;
}

.activeMenuButton {
  background-color: black;
  color: cornflowerblue;
}

#socialMediaTab {
  width: 60px;
  height: 30px;
}

#socialMediaImg {
  height: 34px;
  vertical-align: middle;
}

#homeButton {
  position: fixed;
  float: left;
  left: 0px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
  color:rgba(255, 255, 255, 0);
  visibility: hidden;
}

#homeButton:hover {
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
}