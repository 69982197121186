#resume {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: right;
}

#resumeImageHolder {
  width: 900px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  left: 0px;
  right: 0px;
  text-align: right;
}

#resumeImage {
  width: 100%;
}

#resumeDownload {
  color: #25d91e;
  font-size: 40px;
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
}

#resumeDownload:hover {
  cursor: pointer;
}