#portfolio {
  height: 100%;
  overflow: auto;
}

#sections {
  height: 100%;
  overflow: auto;
}

.section {
  position: relative;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-right: 5%;
  padding-left: 5%;
}

.sectionHolder:nth-child(even) {
  background-color: aliceblue;
}

.sectionHolder:nth-child(odd) {
  background-color: white;
}