#aboutMe {
  overflow: auto;
}

#mainImageHolder {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 40%;
}

#aboutMeMainImage {
  width: 100%;
}

#aboutMeDescription {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 20px;
  width: 57%;
  font-size: 19px;
  text-align: left;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}