#nameplate {
  position: absolute;
  font-size: 80px;
  color:white;
  background-color: rgb(0, 0, 0, .30);
  padding: 20px;
}

#jobTitle {
  font-size: 35px;
}

#degreeTitle {
  font-size: 30px;
}

#home {
  position: relative;
  padding-top: 100px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center; 
  min-height: 700px;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
}

#homeBackgroundImage {
  height: 700px;
}