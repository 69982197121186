.emailInput {
  width: 700px;
  display: block;
  margin: auto;
  border-style: none;
  margin-bottom: 20px;
  font-family: inherit;
  border-bottom-style: solid;
  border-width: 1px;
  font-size: 25px;
  font-family: inherit;
}

#message {
  border-style: solid;
  height: 300px;
  margin-bottom: 20px;
}

#contact {
  padding: 50px;
}

#submit {
  font-size: 15px;
  font-family: inherit;
}

#contactTitle {
  position: relative;
  font-size: 30px;
  margin-bottom: 20px;
}

#rexEmailThankYou {
  height: 500px;
}

#thankYouMessage {
  font-size: 24px;
}