#projectBox, .projectPicture, .projectTech, .projectDescription {
  display: inline-block;
  position: relative;
}

#projects {
  height: 700px;
}

#projectHolder{
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#projectBox, #projectBoxHider {
  width: 100px;
  height: 300px;
  overflow: hidden;
}

#projectBoxHider {
  visibility: hidden;
}

#projectBox:hover #projectBoxHider {
  visibility: visible;
  width: 200px;
  height: 300px;
  background-color: white;
  /*border: #4a87e9;
  border-style: solid;
  border-radius: 20px;
  */
  padding: 10px;
}

#projectBox:hover {
  height: auto;
  width: auto;
  visibility: hidden;
}

.pictureTech {
  position: relative;
}

.projectPicture {
  width: 100%;
  /*border-radius: 15px 15px 0px 0px;*/
}

.projectTech {
  background-color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

#projectsTitle {
  padding-top: 30px;
  font-size: 45px;
  text-align: left;;
}

#projectsSubTitle {
  font-size: 18px;
  text-align: left;
}

#displayProjectPictureHolder {
  width: 30%;
  float: right;
  padding: 10px;
}

#displayProjectPicture {
  width: 100%;
}

#backArrow {
  float: left;
  padding: 20px;
  position: absolute;
  font-size: 30px;
  z-index: 1000;
}

#displayProjectHolder {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

#displayProjectHeading {
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
}

#displayProjectName {
  padding-top: 20px;
  font-size: 40px;
}

#displayProjectTech {
  font-size: 25px;
}

#displayProjectDescription {
  text-align: left;
}